
import TheDrawer from "@/components/shell/app-router/TheDrawer.vue";
import TheSkeletonLoader from "@/components/shell/app-router/TheSkeletonLoader.vue";
import { useStore } from "@/shared/useHelpers";
import { StoreState } from "@/store/store.state.interface";
import { computed, defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "AppRouterPage",
  components: {
    TheDrawer,
    TheSkeletonLoader,
  },
  setup() {
    const store = useStore<StoreState>();

    return {
      skeletonLoader: computed(() => store.state.hoc.skeletonLoader),
      skeletonLoaderType: computed(() => store.state.hoc.skeletonLoader),
      showLoadingSpinner: computed(() => store.state.hoc.showLoadingSpinner),
      ongoingBlockingRequests: computed(() => store.state.hoc.ongoingBlockingRequests),
      ongoingNonBlockingRequests: computed(() => store.state.hoc.ongoingNonBlockingRequests),
      ongoingTableSkeletonRequests: computed(() => store.state.hoc.ongoingTableSkeletonRequests),
    };
  },
});
