var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "consistent-header mt-3"
  }, [_c('v-list', {
    attrs: {
      "color": "transparent",
      "nav": "",
      "dense": ""
    }
  }, [_c('v-list-item-group', {
    attrs: {
      "color": "primary"
    }
  }, _vm._l(_vm.items, function (item, i) {
    return _c('v-list-item', _vm._b({
      key: i,
      attrs: {
        "to": item.link,
        "exact": ""
      }
    }, 'v-list-item', item.extraProperties, false), [_c('v-list-item-icon', [_c('v-icon', {
      domProps: {
        "textContent": _vm._s(item.icon)
      }
    })], 1), _c('v-list-item-content', [_c('v-list-item-title', {
      domProps: {
        "textContent": _vm._s(item.title)
      }
    })], 1)], 1);
  }), 1), _c('table-filter')], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }