var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.ongoingNonBlockingRequests ? _c('v-progress-linear', {
    attrs: {
      "height": "6",
      "indeterminate": "",
      "color": "yellow darken-2"
    }
  }) : _vm._e(), _c('v-container', {
    staticClass: "py-0",
    attrs: {
      "fluid": ""
    }
  }, [_c('v-row', [_vm.$auth.isAuthenticated && _vm.$vuetify.breakpoint.lgAndUp ? _c('v-col', {
    staticClass: "pr-0 drawerMenu",
    attrs: {
      "cols": "0",
      "lg": "2"
    }
  }, [_c('v-fade-transition', {
    attrs: {
      "appear": ""
    }
  }, [_c('v-sheet', {
    attrs: {
      "rounded": "lg"
    }
  }, [_c('TheDrawer')], 1)], 1)], 1) : _vm._e(), _c('v-col', {
    attrs: {
      "cols": "12",
      "lg": "10"
    }
  }, [_c('v-overlay', {
    attrs: {
      "value": _vm.showLoadingSpinner || _vm.ongoingBlockingRequests > 0,
      "z-index": "205"
    }
  }, [_c('v-progress-circular', {
    staticClass: "loader",
    attrs: {
      "size": 200,
      "width": 10,
      "color": "primary",
      "indeterminate": ""
    }
  })], 1), _vm.skeletonLoader.loading ? _c('TheSkeletonLoader', {
    staticClass: "mt-3",
    attrs: {
      "type": _vm.skeletonLoaderType.type
    }
  }) : _vm._e(), _vm.ongoingTableSkeletonRequests > 0 ? _c('TheSkeletonLoader', {
    staticClass: "mt-3",
    attrs: {
      "type": "table"
    }
  }) : _vm._e(), _c('router-view', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.skeletonLoader.loading && _vm.ongoingTableSkeletonRequests === 0,
      expression: "!skeletonLoader.loading && ongoingTableSkeletonRequests === 0"
    }],
    staticClass: "contentArea"
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }